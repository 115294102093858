export const generateHierarchyInfo = (
  baseLabel: string,
  maxHierarchyLevel = 3,
  hierarchyLabels: {
    level: number;
    label: string;
  }[] = []
) => {
  const hierarchyArray = Array(maxHierarchyLevel).fill('');

  const headers = Array(maxHierarchyLevel)
    .fill(baseLabel)
    .map((header, index) => {
      const level = index + 1;
      const hierarchy = hierarchyLabels.find((hierarchy) => hierarchy.level === level);
      return hierarchy ? hierarchy.label : `${header}${level}`;
    });

  return {
    headers,
    hierarchyArray,
  };
};
